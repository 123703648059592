import React from "react";
import styled from "styled-components";

const Loading = () => {
  return (
    <LoadingText>
      Loading
      <span className="dot1">.</span>
      <span className="dot2">.</span>
      <span className="dot3">.</span>
    </LoadingText>
  );
};

export default Loading;

const LoadingText = styled.div`
  font-size: 30px;
  color: white;
  letter-spacing: 5px;

  & > .dot1 { 
    animation: visibility 3s linear infinite;

    @keyframes visibility {
      0% {
      opacity: 1;
      }
      65% {
      opacity: 1;
      }
      66% {
      opacity: 0;
      }
      100% {
      opacity: 0;
      }
    }
  }

  & > .dot2 { 
    animation: visibility2 3s linear infinite;

    @keyframes visibility2 {
      0% {
        opacity: 0;
      }
      21% {
        opacity: 0;
      }
      22% {
        opacity: 1;
      }
      65% {
        opacity: 1;
      }
      66% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  }



  & > .dot3 {
    animation: visibility3 3s linear infinite;
  
    @keyframes visibility3 {
      0% {
        opacity: 0;
      }
      43% {
        opacity: 0;
      }
      44% {
        opacity: 1;
      }
      65% {
        opacity: 1;
      }
      66% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
`;
